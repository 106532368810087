* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  background-color: #353535;
  font-family: "Rubik", sans-serif;
}

nav {
  background-color: #353535;
  color: #b6ad90;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  height: 75px;
  border-bottom: 2px solid #b6ad90;
  border-radius: 0 0 15px 15px;
  position: fixed;
  top: 0px;
  width: 100%;
}

.icon {
  font-size: 28px;
}
.iconCart {
  font-size: 28px;
}

.list ul {
  display: flex;
  justify-content: space-around;
  font-size: 20px;
  list-style: none;
  cursor: pointer;
}
a {
  color: #b6ad90;
  text-decoration: none;
}
main {
  width: 100%;
  height: 550px;
  margin: 20px auto;
  display: flex;
  flex-wrap: wrap;
  grid-template-columns: repeat(3, 1fr);
  height: auto;
  gap: 15px;
}
.block {
  border-radius: 15px;
  /* padding: 15px; */
  font-size: 18px;
}
.block2 {
  border-radius: 15px;
  font-size: 18px;
}

.products {
  width: 270px;
  height: 250px;
  background-color: rgba(255, 255, 255, 0.1);
  text-align: center;
}
.products img {
  width: 100%;
  border-radius: 15px 15px 0 0;
  height: 160px;
}

button {
  width: 100px;
  border-radius: 7px;
  height: 30px;
  border: 2px solid;
  cursor: pointer;
  background-color: #b6ad90;
}

.cart {
  width: 250px;
  margin: 20px auto;
  background-color: rgba(254, 255, 255, 0.1);
  position: relative;
  top: -560px;
}
.row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  text-align: center;
}
.block3 {
  padding: 5px;
}
.btn {
  width: 25px;
  height: 20px;
  border-radius: 50%;
}

.router {
  width: 65%;
  margin: 20px auto;
  padding-top: 80px;

}
.navList {
  width: 100%;
  border: 2px solid #b6ad90;
  height: 100px;
  font-size: 50px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.page {
  border: 2px solid;
  padding: 10px;
}

.main {
  width: 100%;
  height: 550px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  /* gap: 15px; */
  /* position: absolute;
    left: 25px; */
}

@media screen and (max-width: 390px) {
  main {
    grid-template-columns: 1fr;
    width: 85%;
  }
  .main{
    padding: 20px;

  }
  .products {
    height: 300px;
    width: 300px;
  }
  .products img {
    height: 220px;
  }

  .router {
    width: 96%;
  }
  .block2{
    margin: 10px;
  }
}

@media screen and (max-width: 420px) {
  main {
    grid-template-columns: 1fr;
    width: 85%;
  }
  .main{
    padding: 20px;

  }
  .products {
    height: 300px;
    width: 300px;
  }
  .products img {
    height: 220px;
  }

  .router {
    width: 96%;
  }
  .block2{
    margin: 10px;
  }
}
@media screen and (max-width: 350px) {
  main {
    grid-template-columns: 1fr;
    width: 85%;
  }
  .main{
    padding: 20px;

  }
  .products {
    height: 300px;
    width: 300px;
  }
  .products img {
    height: 220px;
  }

  .router {
    width: 96%;
  }
  .block2{
    margin: 10px;
  }
}

@media screen and (max-width: 1115px){
  .router{
    width: 81%;
  }
}
@media screen and (max-width: 1050px){
  .router{
    width: 82%;
  }
}
@media screen and (max-width: 1040px){
  .router{
    width: 83%;
  }
}
@media screen and (max-width: 1000px){
  .router{
    width: 87%;
  }
}

@media screen and (max-width: 980px){
  .router{
    width: 88%;
  }
}
@media screen and (max-width: 820px){
  .router{
    width: 72%;
  }
}